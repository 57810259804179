.uploader__action {
    color: var(--color-palette-blue-600);
    cursor: pointer;
}

.uploader__text {
    align-items: center;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40px;
}

.uploader__disabled_pointer {
    pointer-events: none;

}

.uploader__disabled_pointer > * {
    pointer-events: none;
}

.uploader__disabled_pointer {
    pointer-events: none;

}

.uploader__disabled_pointer > * {
    pointer-events: none;
}

.file_input {
    height: 0;
    visibility: hidden;
    width: 0;
}

.help__text {
    color: var(--color-text-disabled);
}
