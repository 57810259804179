.content {
  display: grid;
  gap: var(--size-spacing-x6);
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
          "pageHeader pageHeader"
          "action action"
          "content content"
          "attachments comments";
  margin: var(--size-spacing-x6) var(--size-spacing-x8);
}

.actionUnit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: action;
}


.attachmentUnit {
  grid-area: attachments;
  display: flex;
  flex-direction: column;
  gap: 8px
}

.commentUnit {
  grid-area: comments;
}

.tableWrapper {
  border: 1px solid var(--color-divider-grey);
  border-radius: var(--size-border-radius-x2);
  grid-area: content;
}