#root {
  grid-area: content;
}

:root {
  --sPT: 5.3333333333rem;
  --sPB: 6rem;
  --sTPB: 2.6666666667rem;
  --bs-body-bg: #ffffff;
  --bs-body-color: #202020;
  --bs-body-font-weight: 400;
  --bs-body-font-size: 15px;
  --bs-body-line-height: calc(20 / 15);
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #fdd835;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #1a73e8;
  --bs-secondary: #6c757d;
  --bs-danger: #fdd835;
  --bs-primary-rgb: 26, 115, 232;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-danger-rgb: 253, 216, 53;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 32, 32, 32;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Beeline Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-body-font-family: var(--bs-font-sans-serif);
  --toplineHeight: 65px;
  --body-color: #171718;
  --outline-body-color: #ffffff;
  /* --secondary: #666666; */
  --secondary: #5e6171;
  --blue: #1a73e8;
  --yellow: #fdd835;
  --grey: #5e6071;
  --red: #ff5555;
  --light-red: #FFF1F1;
  --green: #2ca853;
  --orange: #ff9419;
  --magenta: #bc00b8;
  --purple: #7e00ed;
  --teal: #00adc7;
  --blue-avatar: #e3f2ff;
  --red-avatar: #ffecef;
  --grey-avatar: #f1f1f3;
  --green-avatar: #e7f6eb;
  --orange-avatar: #fff4e1;
  --magenta-avatar: #fae4f7;
  --purple-avatar: #f3e7fe;
  --teal-avatar: #e0f7fb;
  --chips-bg: #e8e9eb;
  --chips-bg-hover: #dedfe2;
  --chips-bg-active-focus: #c8c9ce;
  --remove-btn: #545768;
  --light-control: #e6e6e6;
  --maxw: calc(100vw - 56px);
  --color-background-medium: #FFFFFF;
}

* {
  margin: 0;
  padding: 0;
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  margin: 0;
  --toplineHeight: 65px;
  overflow-y: scroll;
  display: grid;
  grid-template-areas: 'content';
}

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  /* цвет плашки */
  background-color: #b6b7bf;
  border: 4px solid #0000;
  background-clip: content-box;
  border-radius: 100px;
  min-height: 28px;
}

a {
  text-decoration: none;
  color: var(--bs-blue);
  transition-duration: 0.4s;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

.icon {
  height: 1em;
  width: 1em;
  fill: currentColor;
}

.inactiveIcon {
  fill: rgba(25, 28, 52, 0.7);
}

.main-content {
  --maxw: calc(100vw - 56px);
  flex-grow: 1;
  max-width: var(--maxw);
}

.form-check-label {
  font-size: 17px;
  line-height: 24px;
}

.form-check {
  cursor: pointer;
}

.btn {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  font-size: 17px;
  line-height: calc(22 / 17);
  border-radius: 12px;
}

.btn-outline-primary {
  border-color: transparent;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

*, :after, :before {
  box-sizing: border-box;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}


.emptyCounter > span:last-child {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.inactiveText {
  color: var(--color-text-inactive);
}

.customScrollbar::-webkit-scrollbar {
  height: 15px;
}

.customScrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.customScrollbar::-webkit-scrollbar-thumb {
  /* цвет плашки */
  background-color: #b6b7bf;
  border: 4px solid #0000;
  background-clip: content-box;
  border-radius: 100px;
  min-height: 28px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dsb_popover {
  background: black;
  color: white;
  border-radius: 6px;
  z-index: 100;
}

.whiteSpace span, label {
  white-space: normal;
  align-items: flex-start !important;
}

.border-bottom {
  border-bottom: 1px solid var(--color-border);
}


.coping_dropdown .dsb__positioner .dsb__autocomplete__options__item {
  padding: 0 12px !important;

}

.coping_dropdown .dsb__positioner .dsb__autocomplete__options__item:hover {
  background: var(--color-background-base-hover) !important;
}

.coping_dropdown .dsb__autocomplete__options {
  width: 692px !important;
  padding: 0 !important;
}

.selected {
  background: var(--color-background-base-selected);
}

.dsb_snackbar-body-message, .banner-title-text {
  white-space: pre-line;
}

.navigation-drawer {
  height: calc(100vh - 65px);

}

.navigation-drawer > .dsb-navigation-drawer__container {
  height: 100%;
}

.normalWhiteSpace .dsb__autocomplete__options__item {
  white-space: normal;
}

.fieldHelperPosition {

  sup {
    top: 46px;
  }

}

.dsb_label-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}