.file__row {
    align-items: center;
    display: flex;
    width: 100%;
    gap: 16px;
}

.file__image__image {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
}

.file__name {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    flex: 1 1 200px;
    overflow: hidden;
}

.file__name p {
    display: -webkit-box;
    width: 100%;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.file__close_icon {
    cursor: pointer;
}

.file__error_text {
    --color-text-active: var(--color-status-error);
}

.file__actions {
    display: flex;
    align-items: center;
    gap: 16px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: var(--size-spacing-x2);
}

.file_input {
    height: 0;
    visibility: hidden;
    width: 0;
}

.file__calendar_wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.file__registration_calendar {
    position: absolute;
    left: -100px;
    top: 30px;
    z-index: 1;
}