.maskedFieldWrapper {
  > div {
    height: 100%;
  }
}

.maskedField {
  height: 100% !important;
  padding: 6px 16px !important;
  text-align: right;
  background: none !important;
  border: none;
}