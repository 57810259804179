.container {
    display: flex;
    flex-direction: column;
    gap: var(--size-spacing-x6);
}

.editingContainer {
    flex-flow: column-reverse;
}

.uploader {
    align-items: center;
    border: 1px dashed var(--color-border);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    width: 100%;
}
.editingUploader {

}

.uploader__helper_text {
    margin-left: var(--size-spacing-x4);
}

.uploader__helper_text__error {
    color: var(--color-border-error);
}

.uploader:hover {
    background: var(--file-uploader-hover);
}

.uploader__container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: var(--size-spacing-x1);
}

.uploader__error {
    background: var(--error-background-light);
    border-color: var(--color-border-error);
}

.uploader__dragging {
    border: 1px dashed var(--blue-600);
}


.uploader__helper_text {
    margin-left: var(--size-spacing-x4);


}

.uploader__helper_text__error {
    --color-text-inactive: var(--error-status-light);
}

